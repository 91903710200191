import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Text, H3, Button } from "@fatlama/llama-library";
import { useParams } from "react-router-dom";
import Icon from "../Common/Icon";
import Summary from "./Summary";
import Rentals from "./Rentals";
import Items from "./Items";
import { Link } from "react-router-dom";
import { Page } from "../../interfaces";
import Dropdown from "../Common/DropdownPopover";
import GetApi from "../../utils/api";
import Modal from "../Common/Modal";
import ChangeDetailsModal from "./ChangeDetailsModal";
import LogIntoUserAccount from "./LogIntoUserAccount";
import {
  SectionBlock,
  SectionCollapsableBlock,
  TwoGrid,
} from "../SelectedRental/Components";
import Notes from "../CommunicationAndNotes/Notes";
import Communication from "../CommunicationAndNotes/Communication";
import useDuplicates from "../SelectedRental/hooks/useDuplicates";

import DuplicatesList from "../SelectedRental/Duplicates";

const CursorFlex = styled(Flex)`
  cursor: pointer;
`;

const RotatedLeftIcon = styled(Icon)`
  position: relative;
  right: 1px;
  transform: rotate(90deg);
`;

const SelectedCustomer = () => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [changeDetailsModalVisible, setChangeDetailsModalVisible] = useState<
    any
  >(false);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState<any>(
    false
  );
  const api = GetApi();
  let { id } = useParams() as any;
  const duplicates = useDuplicates(id);

  const getCurrentUser = async () => {
    const res = await api.getUser(id);
    const user = res.payload;
    setCurrentUser(user);
  };

  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  if (!currentUser) {
    return null;
  }

  const { transactions, user, addresses, items, analytics } = currentUser;
  const isFlaggedDodgy = user.tags && user.tags.isFlaggedDodgy;
  const markAsDodgy = async () => {
    const response = await api.addUserTags(id, {
      isFlaggedDodgy: isFlaggedDodgy ? false : true,
    });
    if (response.success) {
      await getCurrentUser();
    }
  };

  const pauseUser = async () => {
    const response = await api.pauseUser(id, !user.paused);
    if (response.success) {
      await getCurrentUser();
    }
  };

  const deleteUser = async () => {
    const response = await api.deleteUser(
      id,
      user.deletedAt ? "undelete" : "delete"
    );
    if (response.success) {
      await getCurrentUser();
    }
    setDeleteUserModalVisible(false);
  };

  const title = user.firstName;

  return (
    <>
      {/* <UserFlaggedBanner isFlaggedDodgy={isFlaggedDodgy} /> */}
      <Box p={4}>
        <Flex width="auto" justifyContent="space-between">
          <Link to={Page.customers}>
            <CursorFlex mb={3} alignItems="center">
              <RotatedLeftIcon
                icon="chevron"
                width={10}
                height={10}
                color="midNight"
              />
              <Text mb={0}>Go to all customers</Text>
            </CursorFlex>
          </Link>
          <Dropdown title="Actions">
            <Text mb={0} onClick={() => setChangeDetailsModalVisible(true)}>
              Change details
            </Text>
            <Text
              color={isFlaggedDodgy ? "pomNeon" : "black"}
              mb={0}
              onClick={markAsDodgy}
            >
              {isFlaggedDodgy
                ? "Remove flag from user and allow auto-approval"
                : "Block auto-approval"}
            </Text>
            <Text mb={0} onClick={pauseUser}>
              {user.paused ? "Unpause user" : "Pause user"}
            </Text>
            <Text
              mb={0}
              color={user.deletedAt ? "whale" : "pomNeon"}
              onClick={() => setDeleteUserModalVisible(true)}
            >
              {user.deletedAt ? "Undelete user" : "Delete user"}
            </Text>
            <LogIntoUserAccount userId={id} />
          </Dropdown>
        </Flex>
        <Flex mb={6}>
          <Summary user={user} addresses={addresses} analytics={analytics} />

          <Flex flexDirection="column">
            <Rentals transactions={transactions} email={user.email} />
            <Items items={items} />
          </Flex>
        </Flex>

        <TwoGrid>
          <SectionBlock mb={3}>
            <H3>User notes</H3>

            <Notes userId={id} title={title} noteContextId="On User" />
          </SectionBlock>

          <SectionBlock mb={3}>
            <H3>Send {title} Comms</H3>
            <Communication
              sendingRenter={false}
              userId={id}
              userName={user.firstName}
              noteContextId="On User"
            />
          </SectionBlock>
        </TwoGrid>

        <SectionCollapsableBlock Title={<H3>Duplicate accounts</H3>}>
          <DuplicatesList
            userId={user.id}
            duplicates={duplicates}
            isLoading={false}
            autoApproveDisabled={null}
          />
        </SectionCollapsableBlock>
      </Box>

      <Modal
        isOpen={deleteUserModalVisible}
        onClose={() => setDeleteUserModalVisible(false)}
      >
        <Flex flexDirection="column" alignItems="center" p={2}>
          <H3 mb={7}>
            Are you sure you want to{" "}
            {user.deletedAt ? "undelete the user" : "delete the user"}?
          </H3>

          <Flex justifyContent="flex-end">
            <Box width="auto" mr={2}>
              <Button
                mr={2}
                variant="secondary"
                onClick={() => setDeleteUserModalVisible(false)}
                label="Cancel"
              />
            </Box>
            <Button alert label={"Confirm"} onClick={deleteUser} />
          </Flex>
        </Flex>
      </Modal>

      <Modal
        isOpen={changeDetailsModalVisible}
        onClose={() => setChangeDetailsModalVisible(false)}
      >
        <ChangeDetailsModal
          getCurrentUser={getCurrentUser}
          closeModal={() => setChangeDetailsModalVisible(false)}
          user={user}
        />
      </Modal>
    </>
  );
};

export default SelectedCustomer;
