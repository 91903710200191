import React from "react";
import { Flex, Text } from "@fatlama/llama-library";
import styled from "styled-components";
import {
  AutoApproveDisabled,
  AutoApproveDisabledReason,
  DuplicatedUserType,
} from "../../interfaces";

const AnimatedHeight = styled(Flex)`
  transition: height 0.7s ease;
`;

const UserFlaggedBanner = ({
  autoApproveDisabled,
  duplicateAccounts,
}: {
  autoApproveDisabled: AutoApproveDisabled | null;
  duplicateAccounts?: Record<number, DuplicatedUserType> | null;
}) => {
  const autoApproveDisableMatch =
    duplicateAccounts &&
    autoApproveDisabled &&
    autoApproveDisabled.duplicateUserId &&
    duplicateAccounts[autoApproveDisabled.duplicateUserId];
  if (autoApproveDisabled?.duplicateUserId && !autoApproveDisableMatch) {
    return null;
  }
  return (
    <>
      <a href={autoApproveDisableMatch ? "#duplicates" : ""}>
        <AnimatedHeight
          alignItems="center"
          bg="pomNeon"
          px={3}
          height={autoApproveDisabled ? 60 : 0}
        >
          {autoApproveDisabled && (
            <>
              <Text bold mb={0} color="white">
                Auto approve disabled! Because:{" "}
                {autoApproveDisabled.disabledBecause}
                {autoApproveDisabled.disabledBecause ===
                AutoApproveDisabledReason.CAPPED
                  ? ` (Security value limit: ${autoApproveDisabled.disabledAboveValue})`
                  : ""}
              </Text>
              <Text small mb={0} ml={1} color="white">
                {autoApproveDisableMatch
                  ? `This user has been matched to a duplicate with (${
                      duplicateAccounts[autoApproveDisabled.duplicateUserId]
                        .autoApproveDisabled?.disabledBecause
                    })! CLICK HERE and validate whether this account should have the same restrictions`
                  : ``}
              </Text>
            </>
          )}
        </AnimatedHeight>
      </a>
    </>
  );
};

export default UserFlaggedBanner;
