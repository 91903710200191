import React from "react";
import { H3 } from "@fatlama/llama-library";

import { DataRow, SectionCollapsableBlock } from "./Components";
// import styled from "styled-components";
import { OrderVerification } from "../../interfaces";

// const TwoGrid = styled(Box)`
//   display: grid;
//   grid-column-gap: ${getSpace(3)};
//   grid-template-columns: 1fr 1fr;
// `;

const RULE_EXPLANATIONS = {
  IS_RISKY_1: "Less than 3 completed rentals & has done risky searches",
  IS_RISKY_2:
    "Less than 3 completed rentals, has rentals in risky categories & registered in the last 24 hours",
  IS_RISKY_3:
    "Less than 3 completed rentals, has a rental that starts within 24 hours & high risk item (fraud risk 4 or 5) value is greater than 500 or item value is greater than 2000",
} as Partial<Record<string, string>>;

const OrderVerificationComponent = ({
  orderVerification,
}: {
  orderVerification?: OrderVerification;
}) => {
  if (!orderVerification) {
    return null;
  }
  const inputParsed = JSON.parse(orderVerification.verificationAlgorithmInput);
  const outputParsed = JSON.parse(
    orderVerification?.verificationAlgorithmOutput
  );
  return (
    <SectionCollapsableBlock
      Title={<H3>Auto approval input/output</H3>}
      initiallyClosed
    >
      <H3>INPUT</H3>
      {Object.keys(inputParsed).map((key) => {
        return (
          <DataRow
            reverseSize
            title={key}
            value={inputValueToString(inputParsed[key])}
          />
        );
      })}

      <H3 mt={3}>RESULTS</H3>
      {Object.keys(outputParsed).map((key) => {
        const title =
          key in RULE_EXPLANATIONS ? `${key} (${RULE_EXPLANATIONS[key]})` : key;

        return (
          <DataRow
            reverseSize
            title={title}
            alert={
              typeof outputParsed[key] === "boolean" && outputParsed[key]
                ? true
                : false
            }
            value={
              typeof outputParsed[key] === "object"
                ? outputParsed[key].length
                : outputParsed[key].toString()
            }
          />
        );
      })}
    </SectionCollapsableBlock>
  );
};

function inputValueToString(value: any) {
  if (Array.isArray(value)) {
    return `(${value.length})`;
  }

  if (value === null) {
    return "Not set yet!";
  }

  return value.toString();
}

export default OrderVerificationComponent;
