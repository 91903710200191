import { makeApiCall, RequestType, FetcherConfig } from "./apiUtil";
// import { AppContext } from "../state/Context";
// import { useContext } from "react";
import { QueryRequest } from "../components/Rentals/types";
import {
  CancelledBecauseOf,
  Refund,
  AutoApproveDisabledReason,
  RequirementType,
  DocumentStatus,
  DocumentType,
  SecurityCall,
} from "../interfaces";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { getBaseurl } from "../helpers/environment-helper";

interface GetRentalInput {
  idOrOfferUuid: number;
}

type GetRentalsInput = Pick<
  QueryRequest,
  "pageSize" | "pageNumber" | "filters" | "sortBy"
>;

interface RejectDocumentInput {
  id: number;
  reason: string;
  transactionId?: number;
}

interface CancelRentalInput {
  orderId: number;
  cancelledBecauseOf: CancelledBecauseOf;
  reason: string;
  refund: Refund | null;
}
interface RejectRentalInput {
  orderId: number;
  reason: string;
}

export interface AddUserNoteInput {
  userId: number;
  // noteContextId?: string;
  text: string;
}

interface CancelOfferInput {
  offerUuid: string;
  refund: Refund | null;
  cmsReason: string;
  cancelledBecauseOf: CancelledBecauseOf;
}

interface ChangeTransactionFromDateInput {
  transactionId?: string;
  offerUuid?: string;
  fromDate: Date;
  toDate: Date;
}

interface CreateCMSLogInput {
  userId?: number;
  event: string;
  transactionId?: number;
  offerUuid?: number;
  messageTemplate?: string;
  messageText?: string;
}

interface Api {
  getRental(args: GetRentalInput): any;
  getUser(userId: number): any;
  getUserNotes(userId: number): any;
  getUserDuplicates(userId: number): any;
  getUserSecurityCalls(userId: number): any;
  createSecurityCall({
    userId,
    scheduledFor,
  }: Pick<SecurityCall, "userId" | "scheduledFor">): any;
  updateSecurityCall(securityCall: Partial<SecurityCall>): any;
  getTxStripeData(txId: number): any;
  getRentals(args: GetRentalsInput, config: FetcherConfig): any;
  getUserWarnings(userId: number): any;
  rejectRental(args: RejectRentalInput): any;
  cancelRental(args: CancelRentalInput): any;

  changeTransactionFromDate(args: ChangeTransactionFromDateInput): any;
  getUsers(args: any, config: FetcherConfig): any;
  approveTransaction(txId: number): any;
  getSignedUrls({ urls }: { urls: string[] }): any;
  rejectDocument(args: RejectDocumentInput): any;
  validateDocument(args: { id: number }): any;

  // auth(): any;
  addAutoApproveDisabled(args: {
    disabledBecause: AutoApproveDisabledReason;
    disabledAboveValue?: number;
    userId: number;
  }): any;
  enableAutoApprove(args: { userId: number }): any;
  getUserSearches(userId: number): any;
  addUserNote(args: AddUserNoteInput): any;
  createCMSLog(args: CreateCMSLogInput): any;
  getItemPageData(url: string): any;
  getSeonData(args: { transactionId?: string; offerUuid?: string }): any;
  getUserVerifications(userId: number): any;
  getUserConversations(args: {
    page?: number;
    searchTerm?: string;
    userId: number;
  }): any;
  addExtraRequirements(args: {
    orderId: number;
    requirementTypes: RequirementType[];
  }): any;
  sendEmail(args: { userId: number; body: string; subject: string }): any;
  sendText(args: { userId: number; body: string }): any;
  validateOnlinePresence(args: { reviewedBy: string; uuid: string }): any;
  addUserTags(userId: number, tags: any): any;
  deleteUser(userId: number, action: "delete" | "undelete"): any;
  pauseUser(userId: number, newPausedState: Boolean): any;
  updateUser(userId: number, updateProps: any): any;
  addOnlinePresenceLinks(userId: number, link: string): any;
  logIntoUserAccount({
    impersonatorUsername,
    impersonatedUserId,
  }: {
    impersonatorUsername: string;
    impersonatedUserId: number;
  }): any;
  login({ username, password }: { username: string; password: string }): any;
  deleteItem(a: any): any;
  getUserOtherRentals({ userId }: { userId: number }): any;
}

const GetApi = (): Api => {
  // const { dispatch, getValue } = useContext(AppContext);
  const history = useHistory();

  const apiCall = makeApiCall({ history });
  const baseURL = getBaseurl();
  // const withStateManagement = async (requestObj: any) => {
  //   dispatch({
  //     type: requestObj.key.REQUEST,
  //   });
  //   try {
  //     const response = await apiCall(requestObj);
  //     dispatch({
  //       type: requestObj.key.SUCCESS,
  //       payload: response.payload,
  //     });
  //     return response;
  //   } catch (error) {
  //     dispatch({
  //       type: requestObj.key.FAILURE,
  //       payload: error,
  //     });
  //     return { error: true };
  //   }
  // };
  return {
    // NEW
    getUserOtherRentals: async ({ userId }) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/users/${userId}/other-orders`,
      });
    },
    login: async (params: { username: string; password: string }) => {
      const { username, password } = params;

      const response = await apiCall(
        {
          method: RequestType.POST,
          url: `${baseURL}/authenticate`,
          payload: {
            username,
            password,
          },
        },
        { returnHeaders: true }
      );

      if (!response || !response?.success) {
        return null;
      }
      const { adminId, language, dashboard } = response.payload;

      localStorage.setItem("admin_id", adminId);
      localStorage.setItem("language", language);
      localStorage.setItem("dashboard_url", dashboard);

      const token = response.headers?.get("token");
      // const token = response.headers.get("token");

      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
      }

      return response.payload;
    },

    getUserNotes: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/users/${userId}/note`,
      });
    },

    addUserNote: (payload: { text: string; userId: number }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/users/${payload.userId}/note`,
        payload: {
          text: payload.text,
        },
      });
    },

    getUserSecurityCalls: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/verification/${userId}/security-calls`,
      });
    },

    createSecurityCall: (payload: SecurityCall) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/verification/security-call`,
        payload,
      });
    },

    updateSecurityCall: (payload: SecurityCall) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/verification/security-call`,
        payload,
      });
    },

    getUserWarnings: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/UserWarning`,
        params: {
          filter: JSON.stringify({ userId }),
          range: JSON.stringify([0, 99]),
          sort: JSON.stringify(["createdAt", "DESC"]),
        },
      });
    },

    getRental: ({ idOrOfferUuid }: GetRentalInput) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/orders/${idOrOfferUuid}`,
      });
    },

    getUserSearches: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/users/${userId}/searches`,
      });
    },

    getUserDuplicates: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/users/${userId}/duplicates`,
      });
    },

    getUserVerifications: (orderId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/users/${orderId}/requirements`,
      });
    },

    getSignedUrls: ({ urls }: { urls: string[] }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/verification/documents/get-signed-urls`,
        payload: { urls },
      });
    },

    validateDocument: ({ id }: { id: number }) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/verification/documents/validate`,
        payload: { id },
      });
    },

    rejectDocument: ({ id, reason }) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/verification/documents/reject`,
        payload: { id, rejectedReason: reason },
      });
    },

    approveTransaction: (orderId: number) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/orders/${orderId}/approve`,
      });
    },

    rejectRental: ({ orderId, reason }: RejectRentalInput) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/orders/${orderId}/reject`,
        payload: {
          orderId,
          reason,
          refund: true,
        },
      });
    },

    getRentals: (payload: GetRentalsInput, config) => {
      return apiCall(
        {
          method: RequestType.GET,
          url: `${baseURL}/fat-llama/orders/`,
          params: {
            sortBy: payload.sortBy,
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize || 25,
            filters: payload.filters,
          },
        },
        config
      );
    },

    addExtraRequirements: ({ orderId, requirementTypes }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/verification/requirements`,
        payload: { types: requirementTypes, orderId },
      });
    },

    enableAutoApprove: ({ userId }) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/users/${userId}/enable-auto-approve`,
      });
    },
    addAutoApproveDisabled: ({
      disabledBecause,
      disabledAboveValue,
      userId,
    }: {
      disabledBecause: AutoApproveDisabledReason;
      disabledAboveValue?: number;
      userId: number;
    }) => {
      return apiCall({
        method: RequestType.PUT,
        url: `${baseURL}/fat-llama/users/${userId}/disable-auto-approve`,
        payload: {
          disabledBecause,
          ...(disabledAboveValue ? { disabledAboveValue } : {}),
        },
      });
    },

    sendText: ({ userId, body }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/communication/send-sms`,
        payload: { userId, body },
      });
    },

    sendEmail: ({ userId, body, subject }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/communication/send-email`,
        payload: { userId, body, subject },
      });
    },

    addOnlinePresenceLinks: (userId, link) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fat-llama/verification/documents`,
        payload: {
          url: link,
          userId,
          status: DocumentStatus.VALIDATED,
          suspectedType: DocumentType.ONLINE_PRESENCE,
          confirmedType: DocumentType.ONLINE_PRESENCE,
        },
      });
    },

    // NOT NEW ===========

    cancelRental: ({
      orderId,
      refund,
      cancelledBecauseOf,
      reason,
    }: CancelRentalInput) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/cancel-offer`,
        payload: {
          orderId,
          refundType: refund,
          reason,
          cancelledBecauseOf,
        },
      });
    },
    // GetConversationList
    getUserConversations: ({
      page = 1,
      searchTerm,
      userId,
    }: {
      page?: number;
      searchTerm?: string;
      userId: number;
    }): Promise<any> => {
      const pageNumber = page || 1;
      const queryObj = searchTerm
        ? { searchTerm, userId, pageNumber }
        : { pageNumber, userId };
      const query = qs.stringify(queryObj);

      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/api/v1/admin/get-user-conversations-with-messages?${query}`,
      });
    },

    changeTransactionFromDate: ({
      transactionId,
      offerUuid,
      fromDate,
      toDate,
    }: ChangeTransactionFromDateInput) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/change-transaction-from-date`,
        payload: {
          fromDate,
          toDate,
          transactionId,
          offerUuid,
        },
      });
    },

    createCMSLog: (payload: CreateCMSLogInput) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/create-cms-log`,
        payload,
      });
    },

    getSeonData: ({
      transactionId,
      offerUuid,
    }: {
      transactionId?: string;
      offerUuid?: string;
    }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/get-seon-data`,
        payload: { transactionId, offerUuid },
      });
    },

    validateOnlinePresence: ({ uuid, reviewedBy }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/users/validate-online-presence-link`,
        payload: { linkUuid: uuid, reviewedBy },
      });
    },

    getUser: (userId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/User/${userId}`,
      });
    },
    getTxStripeData: (txId: number) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/fat-llama/orders/${txId}/customer-stripe-data`,
      });
    },
    getUsers: (payload: any, config) => {
      return apiCall(
        {
          method: RequestType.POST,
          url: `${baseURL}/api/v1/admin/get-users`,
          payload: {
            pageNumber: payload.pageNumber,
            pageSize: 20,
            filters: payload.filters,
          },
        },
        config
      );
    },

    addUserTags: (userId: number, tags: any) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/users/update-tags`,
        payload: {
          userId,
          tags,
        },
      });
    },
    deleteUser: (userId: number, action: "delete" | "undelete") => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/admin/delete-user`,
        payload: {
          id: userId,
          action,
        },
      });
    },
    pauseUser: (userId: number, newPausedState: Boolean) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/fatlama-pause-user-items`,
        payload: { paused: newPausedState, adminLenderId: userId },
      });
    },
    updateUser: (userId: number, updateProps: any) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/update-user`,
        payload: { id: userId, ...updateProps },
      });
    },

    getItemPageData: (itemUrl: string) => {
      return apiCall({
        method: RequestType.GET,
        url: `${baseURL}/api/v1/marketplace-next-pages/get-item-page-data/${itemUrl}`,
      });
    },
    logIntoUserAccount: ({ impersonatorUsername, impersonatedUserId }) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/create-impersonation`,
        payload: {
          impersonatorUsername,
          impersonatedUserId,
        },
      });
    },

    deleteItem: (itemId: any) => {
      return apiCall({
        method: RequestType.POST,
        url: `${baseURL}/api/v1/admin/delete-item`,
        payload: { itemId },
      });
    },
  };
};

export default GetApi;
