import React from "react";
import { Flex, Box, Text, H3 } from "@fatlama/llama-library";

import { OrderDataType } from "../../interfaces";

import AutoApproveDisabled from "./AutoApproveDisabled";
interface Props {
  order: OrderDataType;
}

const AutoApproveDisabledSection = ({ order }: Props) => {
  const autoApproveDisabled = order.autoApproveDisabled;
  return (
    <Box>
      <H3 mb={3}>Disabled Auto Approve</H3>
      <Flex mb={3}>
        {!autoApproveDisabled ? (
          <Text>
            This user currently has unrestricted access to the platform within
            our normal rules.
          </Text>
        ) : (
          <Flex>
            <Text color="pomMute">
              This user has a restriction of{" "}
              {autoApproveDisabled?.disabledBecause}{" "}
              {autoApproveDisabled.duplicateUserId
                ? "This is due to a duplicate match. Please confirm the block by submitting below."
                : ""}
            </Text>
          </Flex>
        )}
      </Flex>

      <AutoApproveDisabled
        userId={order.customerId}
        current={autoApproveDisabled || null}
      />
    </Box>
  );
};

export default AutoApproveDisabledSection;
