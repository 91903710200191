import React from "react";
import {
  Flex,
  Box,
  H3,
  Label,
  Input,
  Hr,
  getSpace,
  getBreakpoint,
  Text,
  Modal,
  Button,
  Error,
} from "@fatlama/llama-library";
import DateTimePicker from "react-datetime-picker";
import { DateTimePickerCSS } from "../Common/DateTimePickerCSS";
import { TripleToggle, Toggle } from "../Common/Toggle";
import { SectionBlock } from "./Components";
import { useSecurityCalls } from "./hooks/useSecurityCalls";
import { SecurityCall } from "../../interfaces/index";
import styled from "styled-components";
import formikFieldWrapper from "../Common/formikFieldWrapper";

import { Field, Formik } from "formik";
import TextArea from "../Common/TextArea";
import dayjs from "dayjs";

const FormTextArea = formikFieldWrapper(TextArea);
const FormInput = formikFieldWrapper(Input);

const SecurityCalls = ({ userId }: { userId: number }) => {
  const {
    securityCalls,
    requestSecurityCall,
    logSecurityCall,
    loadingSecurityCalls,
  } = useSecurityCalls({ userId });

  const completedCalls = securityCalls.filter((call) => Boolean(call.calledAt));
  const pendingSecurityCall = securityCalls.find((call) => !call.calledAt);

  return (
    <SectionBlock mb={3}>
      <H3>Security Calls</H3>

      {pendingSecurityCall ? (
        <SecurityCallPending
          call={pendingSecurityCall}
          logSecurityCall={logSecurityCall}
          requestSecurityCall={requestSecurityCall}
        />
      ) : null}

      {completedCalls && completedCalls.length > 0 ? (
        <>
          <Text bold>Completed security call(s)</Text>
          <SecurityCallTable securityCalls={completedCalls} isCompletedCalls />
        </>
      ) : null}

      <Flex justifyContent="flex-end" mt={2}>
        <RequestNewSecurityCall
          userId={userId}
          logSecurityCall={logSecurityCall}
          securityCalls={securityCalls}
          isLoadingSecurityCalls={loadingSecurityCalls}
          requestSecurityCall={requestSecurityCall}
        />
      </Flex>
    </SectionBlock>
  );
};

const SecurityCallTable = ({
  securityCalls,
  isCompletedCalls,
}: {
  securityCalls: SecurityCall[]; // Assuming SecurityCall is an array type
  isCompletedCalls?: boolean;
}) => {
  const [showAllNotes, setShowAllNotes] = React.useState(false);
  return (
    <Box>
      <table>
        <tbody>
          <tr>
            <TH>Requested by</TH>

            {isCompletedCalls ? (
              <>
                <TH>answered</TH>
                <TH>Called by</TH>
                <TH>Called at</TH>
                <TH>Notes</TH>
              </>
            ) : (
              <TH>Scheduled for</TH>
            )}
          </tr>

          {securityCalls.map((call) => {
            return (
              <SecurityCallRow
                call={call}
                isCompletedCall={isCompletedCalls}
                showAllNotes={showAllNotes}
              />
            );
          })}
          {isCompletedCalls ? (
            <tr>
              <TD></TD>
              <TD></TD>
              <TD></TD>
              <TD></TD>
              <TD>
                <Text link onClick={() => setShowAllNotes(!showAllNotes)}>
                  {!showAllNotes ? "View all" : "Hide all"}
                </Text>
              </TD>
            </tr>
          ) : null}
        </tbody>
      </table>
    </Box>
  );
};

const SecurityCallPending = ({
  call,
  logSecurityCall,
  requestSecurityCall,
}: {
  call: SecurityCall;
  logSecurityCall(params: {
    answered: boolean;
    notes: string;
    id: number;
  }): Promise<void>;
  requestSecurityCall: (params: {
    userId: number;
    scheduledFor?: Date;
  }) => Promise<SecurityCall>;
}) => {
  const [logOpen, setLogOpen] = React.useState(false);
  const openLog = () => {
    setLogOpen(true);
  };

  return (
    <Box mb={5}>
      <Modal isOpen={logOpen} onClose={() => setLogOpen(false)}>
        <Box>
          <H3>Log security call</H3>
          <LogSecurityCallHandled
            requestSecurityCall={requestSecurityCall}
            logSecurityCall={logSecurityCall}
            callId={call.id}
            onComplete={() => setLogOpen(false)}
          />
        </Box>
      </Modal>

      <Text bold>Pending security call</Text>

      <SecurityCallTable securityCalls={[call]} />

      <Flex justifyContent="flex-end" mt={2}>
        <Button
          label="Log call"
          onClick={() => {
            openLog();
          }}
        />
      </Flex>
    </Box>
  );
};

const SecurityCallRow = ({
  call,
  isCompletedCall,
  showAllNotes,
}: {
  call: SecurityCall;
  isCompletedCall?: boolean;
  showAllNotes?: boolean;
}) => {
  const [notesOpen, setNotesOpen] = React.useState(false);
  const openNotesClick = () => {
    setNotesOpen(true);
  };
  return (
    <>
      <Modal isOpen={notesOpen} onClose={() => setNotesOpen(false)}>
        <H3>Notes from call</H3>
        <Text>{call.notes}</Text>
      </Modal>

      <tr>
        <TD>
          <Box key={call.id} mb={3}>
            <Text>{call.requestedBy}</Text>
          </Box>
        </TD>

        {isCompletedCall ? (
          <>
            <TD>
              <Box key={call.id} mb={3}>
                <Text bold color={call.answered ? "marineMute" : "pomMute"}>
                  {call.answered?.toString()}
                </Text>
              </Box>
            </TD>
            <TD>
              <Box key={call.id} mb={3}>
                <Text>{call.calledBy}</Text>
              </Box>
            </TD>
            <TD>
              <Box key={call.id} mb={3}>
                <Text>
                  {call.calledAt
                    ? dayjs(call.calledAt).utc().format("D MMM YYYY, HH:mm")
                    : null}
                </Text>
              </Box>
            </TD>

            <TD>
              <Box onClick={openNotesClick} key={call.id} mb={3}>
                <Text link>View</Text>
              </Box>
            </TD>
          </>
        ) : (
          <TD>
            <Box key={call.id} mb={3}>
              <Text>
                {call.scheduledFor
                  ? dayjs(call.scheduledFor).utc().format("D MMM YYYY, HH:mm")
                  : null}
              </Text>
            </Box>
          </TD>
        )}
      </tr>
      {showAllNotes && isCompletedCall ? (
        <tr>
          <TD>
            <Box borderRadius={8}>
              <Text small>"{call.notes}"</Text>
            </Box>
          </TD>
        </tr>
      ) : null}
    </>
  );
};

const validateLogCall = (values: { notes: string; answered: string }) => {
  if (!values.notes) {
    return { notes: "Notes are required" };
  }
  return {};
};

const LogSecurityCallHandled = ({
  callId,
  onComplete,
  logSecurityCall,
  requestSecurityCall,
}: {
  callId: number;
  onComplete(): void;
  logSecurityCall(params: {
    answered: boolean;
    notes: string;
    id: number;
  }): Promise<void>;
  requestSecurityCall: (params: {
    userId: number;
    scheduledFor?: Date;
  }) => Promise<SecurityCall>;
}) => {
  const onSubmitLogSecurityCall = async (
    values: any,
    { setSubmitting }: any
  ) => {
    const answered = values.answered === "Yes" ? true : false;
    await logSecurityCall({ answered, notes: values.notes, id: callId });

    if (values.newCall === "Yes") {
      await requestSecurityCall({ userId: 1, scheduledFor: callDate });
    }
    onComplete();
  };

  const [callDate, setCallDate] = React.useState(new Date());
  return (
    <Formik
      onSubmit={onSubmitLogSecurityCall}
      validate={validateLogCall}
      initialValues={{
        answered: "",
        newCall: "",
        notes: "",
      }}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldTouched,
        resetForm,
      }) => {
        return (
          <Flex flexDirection="column" mt={2}>
            <Box maxWidth="220px" mb={4}>
              <Label>Answered?</Label>
              <TripleToggle
                leftText={"YES"}
                centerText={""}
                rightText={"NO"}
                onLeftClick={() => {
                  setFieldValue("answered", "Yes");
                }}
                onCenterClick={() => {
                  setFieldValue("answered", "");
                }}
                onRightClick={() => {
                  setFieldValue("answered", "NO");
                }}
                leftActive={values.answered === "Yes"}
                centerActive={values.answered === ""}
                rightActive={values.answered === "NO"}
              />
            </Box>

            <Box maxWidth="220px" mb={4}>
              <Label>Request new call?</Label>
              <TripleToggle
                leftText={"YES"}
                centerText={""}
                rightText={"NO"}
                onLeftClick={() => {
                  setFieldValue("newCall", "Yes");
                }}
                onCenterClick={() => {
                  setFieldValue("newCall", "");
                }}
                onRightClick={() => {
                  setFieldValue("newCall", "NO");
                }}
                leftActive={values.newCall === "Yes"}
                centerActive={values.newCall === ""}
                rightActive={values.newCall === "NO"}
              />
            </Box>
            {values.newCall === "Yes" ? (
              <ScheduleCallTime setCallDate={setCallDate} callDate={callDate} />
            ) : null}
            <Box>
              <Label>Call notes</Label>
              <Field
                name="notes"
                type="text"
                label="Call notes"
                placeholder="Enter notes here"
                style={{ height: "200px" }}
                minRows={5}
                maxRows={8}
                required={true}
                onChange={(e: any) => {
                  setFieldValue("notes", e.target.value);
                  setFieldTouched("notes", true, false);
                }}
                component={FormTextArea}
              />
              {errors.notes && touched.notes ? (
                <Error>{errors.notes}</Error>
              ) : null}
              <Button
                // variant='secondary'
                onClick={() => {
                  setFieldTouched("notes", true);
                  setFieldTouched("answered", true);

                  handleSubmit();
                }}
                large
                fullWidth
                label="Save"
                isLoading={isSubmitting}
                disabled={!isValid || !values.answered}
              />
            </Box>
          </Flex>
        );
      }}
    </Formik>
  );
};

const ScheduleCallTime = ({
  setCallDate,
  callDate,
}: {
  setCallDate: (date: Date) => void;
  callDate: Date;
}) => {
  return (
    <Box mb={3} mt={4}>
      <Label>Scheduled call time?</Label>
      <DateTimePickerCSS>
        <DateTimePicker onChange={setCallDate} value={callDate} />
      </DateTimePickerCSS>

      <Text mt={2} small>
        or
      </Text>
      <Flex alignItems="center">
        {[0, 1, 2, 3, 4, 5].map((i) => {
          return (
            <Text
              link
              px={1}
              onClick={() => setCallDate(dayjs().add(i, "hour").toDate())}
            >
              {i === 0 ? "Now" : i}
            </Text>
          );
        })}

        <Text small>hours from now</Text>
      </Flex>
    </Box>
  );
};

const RequestNewSecurityCall = ({
  userId,
  securityCalls,
  requestSecurityCall,
  isLoadingSecurityCalls,
  logSecurityCall,
}: {
  isLoadingSecurityCalls: boolean;
  userId: number;
  securityCalls: SecurityCall[];
  logSecurityCall(params: {
    answered: boolean;
    notes: string;
    id: number;
  }): Promise<void>;
  requestSecurityCall: (params: {
    userId: number;
    scheduledFor?: Date;
  }) => Promise<SecurityCall>;
}) => {
  const hasUnprocessedCall = securityCalls.find((call) => !call.calledAt);

  const [callDate, setCallDate] = React.useState(new Date());
  const [requestCallOpen, setRequestCallOpen] = React.useState(false);
  const [willCallNow, setWillCAllNow] = React.useState(true);
  const [callNowCallId, setCallNowCallId] = React.useState<number | null>(null);

  const onClick = async () => {
    const secCall = await requestSecurityCall({
      userId: userId,
      scheduledFor: willCallNow ? undefined : callDate,
    });
    if (!willCallNow) {
      setCallNowCallId(null);
      setRequestCallOpen(false);
      return;
    }
    setCallNowCallId(secCall.id);
  };

  return (
    <>
      <Box mt={3} width="auto">
        <Button
          disabled={hasUnprocessedCall}
          onClick={() => setRequestCallOpen(true)}
          label="Request security call"
        />
      </Box>

      <Modal
        isOpen={requestCallOpen}
        onClose={() => {
          setRequestCallOpen(false);
          setCallNowCallId(null);
        }}
      >
        <>
          <H3>
            {callNowCallId ? "Log security call" : "Request security call"}
          </H3>
          {!callNowCallId ? (
            <>
              <Box height={300} pb={4}>
                <Label>I will call now</Label>
                <Box maxWidth="130px">
                  <Toggle
                    leftText={"YES"}
                    rightText={"NO"}
                    onLeftClick={() => {
                      setWillCAllNow(true);
                    }}
                    onRightClick={() => {
                      setWillCAllNow(false);
                    }}
                    leftActive={willCallNow}
                    // rightActive={willCallNow}
                  />
                </Box>

                {willCallNow ? null : (
                  <Box mt={2}>
                    <ScheduleCallTime
                      setCallDate={setCallDate}
                      callDate={callDate}
                    />
                  </Box>
                )}
              </Box>

              <FixedButtons>
                <Button
                  variant="secondary"
                  mr={2}
                  label="Cancel"
                  onClick={() => {
                    setRequestCallOpen(false);
                    setCallNowCallId(null);
                  }}
                />

                <Button
                  // disabled={dayjs(callDate).isSame(fromDate, "day")}
                  loading={isLoadingSecurityCalls}
                  label="Request"
                  onClick={onClick}
                />
              </FixedButtons>
            </>
          ) : (
            <LogSecurityCallHandled
              requestSecurityCall={requestSecurityCall}
              callId={callNowCallId}
              logSecurityCall={logSecurityCall}
              onComplete={() => {
                setRequestCallOpen(false);
                setCallNowCallId(null);
              }}
            />
          )}
        </>
      </Modal>
    </>
  );
};

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const FixedButtons = styled(Flex)`
  z-index: 2;
  justify-content: flex-end;
  position: absolute;
  padding: ${getSpace(2)} ${getSpace(3)} ${getSpace(2)} ${getSpace(3)};
  background-color: white;
  bottom: 0;
  right: 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 16px 16px;
  @media (max-width: ${getBreakpoint(3)}) {
    bottom: 50px;
    box-shadow: none;
    width: 100%;
    justify-content: center;
  }
`;

export default SecurityCalls;
