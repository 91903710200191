import { useEffect, useState } from "react";

import GetApi from "../../../utils/api";

import { UserWarnings } from "../../../interfaces/index";

const useUserWarnings = (userId?: number) => {
  const api = GetApi();
  const [items, setItems] = useState<UserWarnings[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const getItems = async () => {
    if (!userId) {
      return;
    }
    try {
      setError(undefined);
      setIsLoading(true);

      const res = await api.getUserWarnings(userId);

      if (!res.success) {
        setError("Cannot get user warnings");
        return;
      }
      const data = res.payload;

      setItems(data);
    } catch (error) {
      setError("Cannot get user warnings");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return {
    items,
    isLoading,
    error,
  };
};

export default useUserWarnings;
