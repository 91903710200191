import styled from "styled-components";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

export const DateTimePickerCSS = styled.div`
  /* General container styling */

  .react-datetime-picker {
    font-family: Arial, sans-serif;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
  }

  .react-datetime-picker:focus-within {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    border-color: #007bff;
  }

  /* Input field styling */
  .react-datetime-picker__wrapper {
    display: flex;
    align-items: center;
    border: none;
    padding: 8px 10px;
    gap: 5px;
  }

  .react-datetime-picker__inputGroup {
    display: flex;
    align-items: center;
  }

  .react-datetime-picker__inputGroup__input {
    border: none;
    font-size: 1rem;
    padding: 0 4px;
    color: #333;
  }

  .react-datetime-picker__inputGroup__input:focus {
    outline: none;
    color: #007bff;
  }

  .react-datetime-picker__inputGroup__divider {
    color: #999;
  }

  /* Calendar button styling */
  .react-datetime-picker__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.2s ease-in-out;
  }

  .react-datetime-picker__button:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }

  .react-datetime-picker__button svg {
    fill: #007bff;
    width: 20px;
    height: 20px;
  }

  /* Calendar popup styling */
  .react-calendar {
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .react-calendar__tile {
    text-align: center;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  .react-calendar__tile:enabled:hover {
    background-color: #007bff;
    color: white;
  }

  .react-calendar__tile--active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }

  /* Navigation buttons */
  .react-calendar__navigation button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    transition: background-color 0.2s ease-in-out;
  }

  .react-calendar__navigation button:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }

  /* Disabled styling */
  .react-datetime-picker__inputGroup__input:disabled {
    background-color: #f9f9f9;
    color: #aaa;
  }

  .react-datetime-picker__button:disabled svg {
    fill: #aaa;
  }
`;
