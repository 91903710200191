import { useEffect, useState } from "react";
import { uniqBy, prop } from "ramda";

import GetApi from "../../../utils/api";
import { SecurityCall } from "../../../interfaces";

export const useSecurityCalls = ({
  userId,
}: {
  userId: number;
}): {
  securityCalls: any[];
  logSecurityCall: (
    input: Pick<SecurityCall, "id" | "answered" | "notes">
  ) => Promise<void>;
  requestSecurityCall: (input: Partial<SecurityCall>) => Promise<SecurityCall>;
  loadingSecurityCalls: boolean;
} => {
  const [securityCalls, setSecurityCalls] = useState<any[]>([]);
  const [loadingSecurityCalls, setLoadingSecurityCalls] = useState(false);

  const api = GetApi();

  const getSecurityCalls = async () => {
    if (!userId) {
      return;
    }
    setLoadingSecurityCalls(true);
    const response = await api.getUserSecurityCalls(userId);
    console.log("NEW+", response);

    if (response.success) {
      setSecurityCalls(response.payload.secCalls);
    }
    setLoadingSecurityCalls(false);
  };

  const requestSecurityCall = async ({
    scheduledFor,
  }: Pick<SecurityCall, "scheduledFor">) => {
    setLoadingSecurityCalls(true);
    const response = await api.createSecurityCall({
      userId,
      scheduledFor,
    });

    if (response.success) {
      await getSecurityCalls();
    }
    setLoadingSecurityCalls(false);

    return response.payload.secCall;
  };

  const logSecurityCall = async ({
    answered,
    id,
    notes,
  }: Pick<SecurityCall, "answered" | "notes" | "id">) => {
    setLoadingSecurityCalls(true);
    const response = await api.updateSecurityCall({
      notes,
      id,
      answered,
    });

    if (response.success) {
      await getSecurityCalls();
    }
    setLoadingSecurityCalls(false);
  };

  useEffect(() => {
    getSecurityCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    securityCalls,
    requestSecurityCall,
    logSecurityCall,
    loadingSecurityCalls,
  };
};
