import React, { useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Box, H3, Text, Hr, Span, Flex, Button } from '@fatlama/llama-library'
import currencyMap from '../../constants/currencyMap'

const Image = styled.img`
  border-radius: 8px;
  margin-right: 16px;
`

const Item = ({ item }: { item: any }) => {
  const currencySymbol = currencyMap[item.currency]
  const image = item.imgUrls && item.imgUrls[0]
  return (
    <Flex>
      <Image width='74px' height='55px' src={`https://assets.fatllama.com/images/small/${image}`} />
      <Flex>
        <Box>
          <Text bold>
            {item.itemName}
            {' - '}
            <Span>
              {currencySymbol}
              {item.price / 100}
              {item.weeklyPrice
                ? ` / ${currencySymbol}${Math.round(item.weeklyPrice * 7) / 100}`
                : ''}
              {item.monthlyPrice
                ? ` / ${currencySymbol}${Math.round(item.monthlyPrice * 30) / 100}`
                : ''}
            </Span>
          </Text>
          <Text color='copyTwo'>
            {item.categories && item.categories.join(' > ')} - Created{' '}
            {dayjs(item.createdAt).utc().format('D MMM YYYY')}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

const ClickAbleLink = styled.a`
  cursor: pointer;
`

const Items = ({ items }: { items: any[] }) => {
  const [itemsToShow, setItemsToShow] = useState(items.slice(0, 5))
  return (
    <Box>
      <H3>Items</H3>
      <Hr mb={2} />

      {itemsToShow.map((item: any) => {
        return (
          <>
            <ClickAbleLink target='_blank' href={`https://fatllama.com${item.rentUrl}`}>
              <Item item={item} />
            </ClickAbleLink>

            <Hr mb={2} />
          </>
        )
      })}
      {itemsToShow.length !== items.length && (
        <Flex justifyContent='flex-end'>
          <Button variant='secondary' large label='See all' onClick={() => setItemsToShow(items)} />
        </Flex>
      )}
    </Box>
  )
}

export default Items
