// NEW SHIT
export enum TransactionStatus {
  /** pending user action e.g. verification or a request by admin */
  PRE_AUTHORIZED = "PRE_AUTHORIZED",
  /** We could not auto approve the user, pending manual security intervention */
  SECURITY_REVIEW = "SECURITY_REVIEW",
  /** waiting for lender acceptance */
  FL_APPROVED = "FL_APPROVED",
  /** lender has accepted, rental may have started. Have not paid out lender */
  ESCROW = "ESCROW",

  /** transaction is parked for ops issue and must be manually moved to a new status */
  QUARANTINED = "QUARANTINED",
  AWAITING_BANK_DETAILS = "AWAITING_BANK_DETAILS",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
  DEPOSIT = "DEPOSIT",
  DISPUTE_PAYOUT = "DISPUTE_PAYOUT",
  PRE_AUTHORIZED_CANCELLED = "PRE_AUTHORIZED_CANCELLED",
  DOC_REVIEW = "DOC_REVIEW",

  FAKE_RENTAL = "FAKE_RENTAL",
}

export interface SecurityCall {
  id: number;
  requestedBy: string;
  calledBy?: string;
  answered?: boolean;
  calledAt?: Date;
  userId: number;
  notes?: string;
  scheduledFor?: Date;
  createdAt: Date;
  updatedAt: Date;
}
export interface OrderVerification {
  id: number;
  orderId: number;
  autoApprove: boolean;
  createdAt: Date;
  updatedAt: Date;
  verificationAlgorithmInput: string;
  verificationAlgorithmOutput: string;
}
export interface UserStats {
  userId: number;
  vendorProducts: number;
  customerCompletedOrders: number;
  customerStartedOrders: number;
  customerPaidOrders: number;
  customerLtv: number;
  customerFirstBuy: Date;
  customerAverageRating: number;
  customerRatings: number;
  vendorCompletedOrders: number;
  vendorPublishedProducts: number;
  vendorPublishedProductsAfterFirstSell: number;
  vendorProductLocationsAfterFirstSell: number;
  vendorSales: number;
  vendorProductLocations: number;
  vendorResponseTime: { days: number; hours: number; minutes: number } | null;
  vendorResponseRate: number;
  vendorPaidPayouts: number;
  vendorAverageRating: number;
  vendorRatings: number;
  vendorStartedOrders: number;
  vendorPaidOrdersLast365Days?: number;
  vendorRating5RateLast365Days?: number;
}

export enum DocumentStatus {
  PENDING_REVIEW = "PENDING_REVIEW",
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

export enum OFFER_STATUS {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  APPROVED = "APPROVED",
}

export enum DocumentType {
  UNKNOWN = "UNKNOWN",
  PASSPORT = "PASSPORT",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  PROOF_OF_ADDRESS = "PROOF_OF_ADDRESS",
  ID_CARD = "ID_CARD",
  // important to keep the value for PHOTO_SELFIE as 'SELFIE' to make it backwards compatible
  PHOTO_SELFIE = "SELFIE",
  PROOF_OF_EMPLOYMENT = "PROOF_OF_EMPLOYMENT",
  VIDEO_SELFIE = "VIDEO_SELFIE",
  CARD_CHECK = "CARD_CHECK", // not actually a document - represents that the user has done a card check
  USES_CREDIT_CARD = "USES_CREDIT_CARD", // not actually a document - represents that the user has used credit card
  APPROVED_BY_USER = "APPROVED_BY_USER",
  APPROVED_BY_ADMIN = "APPROVED_BY_ADMIN",
  TYPEFORM_SUBMITTED = "TYPEFORM_SUBMITTED",
  RENTAL_AGREEMENT_ACCEPTED = "RENTAL_AGREEMENT_ACCEPTED",

  ONLINE_PRESENCE = "ONLINE_PRESENCE",
}

export interface Conversation {
  id: number;
  itemId: number;
  lenderId: number;
  borrowerId: number;
  lastMessageAt: string;
  firstName: string;
  profileImgUrl: string;
  otherUserFirstName: string;
  otherUserProfileImgUrl: string;
  otherUserProfileUuid: string;
  itemName: string;
  description: string;
  imgUrls: string[];
  fromDate: string;
  postcode: string;
  lat: number;
  lng: number;
  toDate: string;
  itemUrl: string;
  messages: Message[];
  city: string;
}

export interface Message {
  id: number;
  value: string;
  read: boolean;
  userId: number;
  conversationId: number;
  createdAt: string;
  updatedAt: string;
  adminRead: false;
  type: string;
  externalUuid: null;
  readAt: string;
  details: any;
}

export interface CMSTableRental {
  orderId: number;
  isOffer: boolean;
  itemNames: string[];
  transactionIds: number[];
  createdAt: Date;
  totalEstimatedValue: number;
  rentalVolume: number;
  orderFullTotal: number;
  imgUrl?: string;
  status: TransactionStatus;
  currency: string;
  fromDate: Date | null;
  orderStatus?: OrderStatusType;
  toDate: Date | null;
  orderStatusUpdatedAt?: Date;
  securityStatus: SecurityStatusType;
  paymentStatus: PaymentStatusType;
  borrowerEmail: string;
  timeCustomerWaitingForReview?: Date;
  lenderEmail: string;
  offerUuid?: string;
  securityCall?: SecurityCall;
}

export interface CMSRental {
  idOrOfferUuid: string;
  offerUuid?: string;
  isOffer: boolean;
  items: any[];
  transactionIds: number[];
  createdAt: Date;
  orderFullTotal: number;
  totalEstimatedValue: number;
  status: TransactionStatus;
  cancelledBy?: string;
  lenderApprovedAt?: Date;
  flApprovedAt?: Date;
  approvedBy?: string;
  currency: string;
  fromDate: Date;
  toDate: Date;
  borrowerName: string;
  movedToSecurityReviewAt?: Date;
  borrowerEmail: string;
  borrowerId: number;
  lenderEmail: string;
  lenderName: string;
  lenderId: number;
  borrowerTags?: any;
  lenderTags?: any;
  totalDiscount: number;
  creditUsed: number;
  borrowerFee: number;
  lenderFee: number;
  netRevenue: number;
  lenderEarnings: number;
  originalPromocode?: string;
  isBorrowerFlaggedDodgy?: boolean;
  lenderNotActivated?: boolean;
  verifLogs?: any;
}

export interface RuckifyRental {
  ruckifyBookingId: number;
  ownerId: number;
  ownerFirstName: string;
  renterId: number;
  renterFirstName: string;
  itemIds: number[];
  itemNames: string[];
  startDate: Date;
  endDate: Date;
  status: string;
  ownerInvoiceS3Postfix: string;
  renterInvoiceS3Postfix: string;
  ownerEarnings: number;
  renterTotalCost: number;
  currency: string;
}

export enum CancelledBy {
  LENDER = "L",
  BORROWER = "B",
  FATLLAMA = "FL",
  AUTO = "A",
}

export enum Refund {
  F = "F",
  P = "P",
}

export enum CancelledBecauseOf {
  BORROWER = "BORROWER",
  LENDER = "LENDER",
}

// OLD SHIT
export enum ItemIntegrationLevel {
  STOCKLESS = "STOCKLESS",
  MINIMAL = "MINIMAL",
  STOCK = "STOCK",
}
type Maybe<T> = T | null;

export interface PartnershipAdminUser {
  id: number;
  partnershipUuid: string;
  firstName?: string;
  lastName?: string;
  email: string;
  passwordHash?: string;
  userRole: PartnerAdminUserRole;
  createdAt: Date;
  active: boolean;
}

export enum PartnerAdminUserRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum Page {
  overview = "/overview",
  rentals = "/rentals",
  stock = "/stock",
  customers = "/customers",
  error = "/error",
  items = "items",
  conversations = "/conversations",
  leads = "/leads",
  categories = "/categories",
  customerRentals = "/customer-rentals",
}

export enum AdminCancelMode {
  CANCEL_SUBSCRIPTION_GIVE_FULL_REFUND_WE_HAVE_THE_ITEMS = "CANCEL_SUBSCRIPTION_GIVE_FULL_REFUND_WE_HAVE_THE_ITEMS",
  CANCEL_SUBSCRIPTION_NO_REFUND_UNTIL_ITEM_RETURNED = "CANCEL_SUBSCRIPTION_NO_REFUND_UNTIL_ITEM_RETURNED",
  END_SUBSCRIPTION_WE_HAVE_THE_ITEM_CHARGE_EARLY_CANCELLATION_FEE_IF_APPLICABLE = "END_SUBSCRIPTION_WE_HAVE_THE_ITEM_CHARGE_EARLY_CANCELLATION_FEE_IF_APPLICABLE",
}

export interface EarlyCancellationOutput {
  leaseTimeUnit: LeaseTimeUnit;
  isThereLowerMinimumTerm: boolean;
  currentMinimumTerm: number;
  currentAmountPerTermInPence: number;
  lowerMinimumTerm: number;
  lowerAmountPerTermInPence: number;
  termsElapsedSinceStart: number;
  currentPaidWithinTermsElapsedInPence: number;
  lowerPaidWithinTermsElapsedInPence: number;
  totalToBePaidWithCancellationInPence: number;
}

export enum RentalProcessIntegrationLevel {
  CHECKOUT_ONLY = "CHECKOUT_ONLY",
  MICROSITE = "MICROSITE",
}

export enum DeliveryIntegrationLevel {
  NONE = "NONE",
  FL_BASED = "FL_BASED",
}

export enum VerificationStrategy {
  LEGACY = "LEGACY",
  ITEM_BASED = "ITEM_BASED",
}

export enum DeliveryFeeCalculationStrategy {
  MAX_FLAT_FEE_IN_BASKET = "MAX_FLAT_FEE_IN_BASKET",
  SUM_FLAT_FEES_IN_BASKET = "SUM_FLAT_FEES_IN_BASKET",
}

export enum LeaseTimeUnit {
  DAY = "DAY",
  MONTH = "MONTH",
}

export enum SignupSource {
  FATLAMA = "fatlama",
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

export type PricingDiscountBoundaries = {
  [timeUnit in LeaseTimeUnit]: Record<number, number>;
};

export interface Partnership {
  uuid: string;
  name: string;
  subdomain: string;
  displayName: string;
  userId?: number;
  profileBannerImgUrl?: string;
  itemIntegrationLevel?: ItemIntegrationLevel;
  rentalProcessIntegrationLevel?: RentalProcessIntegrationLevel;
  deliveryIntegrationLevel?: DeliveryIntegrationLevel;
  verificationStrategy?: VerificationStrategy;
  freeDeliveryAboveBasketValueInPence?: number;
  freeDeliveryAboveLeaseTermTimeUnit?: LeaseTimeUnit;
  freeDeliveryAboveLeaseTermValue?: number;
  deliveryFeeCalculationStrategy?: DeliveryFeeCalculationStrategy;
  stripeConnectedAccountId?: string;
  borrowerFeePercentage: number;
  lenderFeePercentage: number;
  defaultLeaseTimeUnit: LeaseTimeUnit;
  defaultLeaseMinimumTerm: number;
  pricingDiscountBoundaries: PricingDiscountBoundaries;
  minimumLeaseValuePerTimeUnit: number;
  ceilFinalPrice: boolean;
  logoImgUrl?: string;
  hardcodeDeliveryDateToNDaysAfterTransaction?: number;
  isTestPartnership: boolean;
  emailLogoUrl?: string;
}

export interface RMSTransaction {
  uuid: string;
  orderNumber?: string;
  basketUuid: string;
  userId: number;
  startDate?: Date;
  paymentPlanUuid?: string;
  basketItemCount?: number;
}

export enum DeliveryDateStrategy {
  DELIVERY_CONFIRMATION_CALL = "DELIVERY_CONFIRMATION_CALL",
}

interface ItemTags {
  [key: string]: number | boolean | string;
}

export enum PartnershipName {
  MADE = "made",
  FLEX = "flex",
  TEST_FLEX = "flextest",
  DEV = "dev",
  COMMERCIAL = "commercial",
  JOHN_LEWIS = "johnlewis",
  SOFOLOGY = "sofology",
}

export interface Item {
  id: number;
  originalItemId?: number;
  itemName: string;
  /**
   * @deprecated
   */
  condition: string | null;
  description: string;
  price: number | null;
  deposit: number | null;
  estimatedValue: number | null;
  /**
   * @deprecated
   */
  fromDate: Date | null;
  /**
   * @deprecated
   */
  toDate: Date | null;
  itemType: string;
  userId: number;
  postcode: string;
  createdAt: Date;
  updatedAt: Date;
  itemUrl: string;
  lat: number | null;
  lng: number | null;
  /**
   * WARNING: this is technically a nullable column but in practice it's not
   */
  imgUrls: string[];
  categories: string[] | null;
  approved: boolean | null;
  deleted: boolean | null;
  weeklyPrice: number | null;
  monthlyPrice: number | null;
  optimiseListing: string | null;
  /**
   * @deprecated
   */
  newCategories: Record<string, any> | null;
  newItem: Date | null;
  currency: string | null;
  referrerId: number | null;
  tags: ItemTags;
  isFakeListing: boolean | null;
  itemLocationUuid: string | null;
  city: string | null;
  region: string | null;
  /**
   * @deprecated
   */
  vehicleFlInsured: boolean | null;
  /**
   * @deprecated
   */
  vehicleRegistration: string | null;
  systemEstimatedValue: number | null;
  minRentalDays: number | null;
  isForSale: boolean | null;
  isForRent: boolean | null;
  salePrice: number | null;
  canBeDelivered: boolean | null;
  canBeCollected: boolean | null;
  saleCondition: string;
  needsConfirmItemValue: boolean | null;
  gtins: string[] | null;
  deliveryEstimateDaysMin: number | null;
  deliveryEstimateDaysMax: number | null;
  isDraft: boolean | null;
  sellerSku: string | null;
  stockLevel: number | null;
  paused: boolean | null;
  inSitemap: boolean | null;
  extraFields?: any;
  flatDeliveryFeeInPence?: number;
  pricingDiscountBoundaries?: any;
  deliveryDateStrategy?: DeliveryDateStrategy;
  hardcodeDeliveryDateToNDaysAfterTransaction?: number;
  minimumBasketLeaseValuePerTimeUnit?: number;
}

export interface BasketRow {
  uuid?: string;
  leaseTimeUnit?: LeaseTimeUnit;
  leaseMinimumTerm?: number;
  preRentalDeliveryUuid?: string | null;
  deliveryAddressUuid?: string | null;
  deliveryDate?: Date;
  partnershipUuid?: string;
  userId?: number;
  startDate?: Date;
  endDate?: Date;
  isImmutable?: boolean;
  // deliveryDateStrategy?: DeliveryDateStrategy
}

export enum RequirementType {
  HAS_IDENTITY_DOC = "HAS_IDENTITY_DOC",
  HAS_VIDEO_SELFIE = "HAS_VIDEO_SELFIE",
  HAS_PROOF_OF_ADDRESS = "HAS_PROOF_OF_ADDRESS",
  HAS_PROOF_OF_EMPLOYMENT = "HAS_PROOF_OF_EMPLOYMENT",
  HAS_ONLINE_PRESENCE_LINK = "HAS_ONLINE_PRESENCE_LINK",
}

export interface OnlinePresence {
  uuid: string;
  userId: number;
  linkType: LinkType;
  linkValue: string;
  addedBy: LinkSource;
  optOutReason: string | null;
  visibleOnProfile: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  madeVisibleAt: Date | null;
  status: LinkStatus;
  reviewedAt: Date | null;
  reviewedBy: string | null;
  rejectedReason: LinkRejectedReason | null;
}

export enum LinkSource {
  USER = "user",
  ADMIN = "admin",
  SYSTEM = "system",
}

export enum LinkType {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  LINKED_IN = "linkedin",
  PERSONAL = "personal",
  YOUTUBE = "youtube",
  OTHER = "other",
  MANDY = "mandy",
  // We allow a user to opt out of supplying links, but we capture it as a special
  // type of link for simplicity.
  OPT_OUT = "opt_out",
}

export enum LinkStatus {
  /** LEGACY represents links that were added before we built functionality to validate/reject them */
  LEGACY = "LEGACY",
  PENDING_REVIEW = "PENDING_REVIEW",
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
}

export enum LinkRejectedReason {
  NAME_DOES_NOT_MATCH = "NAME_DOES_NOT_MATCH",
  NO_CONNECTION_TO_USER = "NO_CONNECTION_TO_USER",
  INSUFFICIENT_NUMBER_OF_CONNECTIONS = "INSUFFICIENT_NUMBER_OF_CONNECTIONS",
  PROFILE_IS_PRIVATE = "PROFILE_IS_PRIVATE",
  INVALID_SOCIAL_MEDIA_TYPE = "INVALID_SOCIAL_MEDIA_TYPE",
  NO_ACTIVITY_IN_LAST_6_MONTHS = "NO_ACTIVITY_IN_LAST_6_MONTHS",
  NO_POSTS = "NO_POSTS",
  POSSIBLE_FAKE_PROFILE = "POSSIBLE_FAKE_PROFILE",
  NOT_VALID_URL = "NOT_VALID_URL",
}

export enum DocumentSubtype {
  BANK_STATEMENT = "BANK_STATEMENT",
  UTILITY_BILL = "UTILITY_BILL",
  PHONE_BILL = "PHONE_BILL",
  COUNCIL_TAX = "COUNCIL_TAX",
  HMRC_DOCUMENT = "HMRC_DOCUMENT",
  PAYSLIP = "PAYSLIP",
  CLIENT_INVOICE = "CLIENT_INVOICE",
  TAX_RETURN = "TAX_RETURN",
}

// export interface DuplicateAccount {
//   dateOfBirth: string | null;
//   email: string;
//   firstName: string;
//   id: number;
//   lastName?: string;
//   stripeFirstName?: string;
//   stripeLastName?: string;
//   matchTypes: string[];
//   resolutions?: any[];
//   stripeAddressPostalCode?: string;
//   telephone?: string;

//   isSelfReferrer?: boolean;
//   tags?: any;
// }

export enum AutoApproveDisabledReason {
  // Capped we block auto approve and add in the notes (in future in code) the value which they can rent up until
  CAPPED = "CAPPED",
  // This is often a new user, who does'nt look like they're steal, but they arent able to pass checks for financial liability or that they have experience/trust on the platform yet.
  // Its likely this tag could be removed.
  NOT_YET_TRUSTED = "NOT_YET_TRUSTED",
  // This is someone who we do not trust and never want to rent. This will probably not be removed.
  // We migth also add them to blocked_users
  UNTRUSTED = "UNTRUSTED",
  // Someone who has proven to be untrusted - aka stolen something. They should ALWAYS be on the blocked list
  NON_RETURN = "NON_RETURN",
}
export interface AutoApproveDisabled {
  id: number;
  userId: number;
  disabledBecause: AutoApproveDisabledReason;
  disabledBy: string;
  deletedBy: string | null;
  deletedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  duplicateUserId: number;
  disabledAboveValue: number | null;
}

export type DuplicatedUserType = Pick<
  User,
  | "id"
  | "addressZip"
  | "firstName"
  | "lastName"
  | "email"
  | "birthDate"
  | "telephone"
  | "deletedAt"
> & {
  referrerUsedCouponIds?: number[];
  referrerId?: number;
  matchTypes?: string[];
  isSelfReferrer?: boolean;
  resolutions?: CustomerResolutionResult[];
  autoApproveDisabled?: AutoApproveDisabled;
  tags?: any;
  stats?: UserStats;
  documents: Document[];
};

// export interface Duplicates {
//   selfReferrer?: DuplicateAccount & { referrerUsedPromocodes: any[] };
//   accounts: Record<string, DuplicateAccount>;
// }

export enum CaseType {
  UNKNOWN = "UNKNOWN",
  DAMAGE_LOW_VALUE = "DAMAGE_LOW_VALUE",
  DAMAGE_MEDIUM_VALUE = "DAMAGE_MEDIUM_VALUE",
  DAMAGE_HIGH_VALUE = "DAMAGE_HIGH_VALUE",
  LATE_RETURN = "LATE_RETURN",
  NON_RETURN_TO_LATE_RETURN = "NON_RETURN_TO_LATE_RETURN",
  NON_RETURN_LOW_VALUE = "NON_RETURN_LOW_VALUE",
  NON_RETURN_MEDIUM_VALUE = "NON_RETURN_MEDIUM_VALUE",
  NON_RETURN_HIGH_VALUE = "NON_RETURN_HIGH_VALUE",
  ITEM_DID_NOT_FUNCTION = "ITEM_DID_NOT_FUNCTION",
  ITEM_NOT_AS_DESCRIBED = "ITEM_NOT_AS_DESCRIBED",
  RENTAL_DID_NOT_HAPPEN = "RENTAL_DID_NOT_HAPPEN",
  SWAP = "SWAP",
}

export type CustomerResolutionResult = {
  id: Resolution["id"];
  customerId: OrderDataType["customerId"];
  lenderAmountPaidPence: number;
  borrowerAmountRecoveredPence: number;
  caseTypes: CaseType[];
  orderId: Resolution["orderId"];
};

export type Duplicates = {
  accounts?: Record<number, DuplicatedUserType>;
  selfReferrer?: DuplicatedUserType & {
    referrerUsedCouponIds: number[];
    referrerId: ReferralHistory["giverUserId"];
  };
};

export type Resolution = {
  id: number;
  caseManager: string | null;
  daysSinceReported: number | null;
  amountPaidLender: number | null;
  amountPaidLenderGog: number | null;
  caseReportedAt: Date | null;
  caseType: string | null;
  deadlineLender: Date | null;
  deadlineBorrower: Date | null;
  lenderEmail: string | null;
  borrowerEmail: string | null;
  lenderId: number | null;
  borrowerId: number | null;
  disputeConclusion: string | null;
  orderId: number | null;
  caseSummary: string | null;
  initialOpsActionsTaken: string | null;
  borrowerCallNotes: string | null;
  lenderCallNotes: string | null;
  borrowerUsefulInfo: string | null;
  lateReturnDate: Date | null;
  repairComments: string | null;
  proofOfPurchaseComments: string | null;
  lenderInsuranceType: string | null;
  borrowerLiabilityLateReturn: number | null;
  borrowerLiabilityDamage: number | null;
  borrowerLiabilityNonReturn: number | null;
  borrowerLiabilityTotal: number | null;
  borrowerLiabilityFees: number | null;
  borrowerLiabilityTotalFees: number | null;
  closedAtLender: Date | null;
  closedAtBorrower: Date | null;
  daysToCloseLender: number | null;
  daysToCloseBorrower: number | null;
  borrowerLiabilityOutstanding: number | null;
  borrowerLiabilityRecoveredRepaymentPlan: number | null;
  borrowerLiabilityRecoveredBankTransfer: number | null;
  borrowerLiabilityRecoveredGrabCash: number | null;
  borrowerLiabilityRecoveredTotal: number | null;
  borrowerLiabilityOutstandingExcludingFees: number | null;
  borrowerLiabilityFeesCollected: number | null;
  securityIntervention: Date | null;
  daysOpenSinceSecurity: number | null;
  typeOfMisconduct: string | null;
  lateReturnPaid?: boolean;
  initialDiputeValue: number | null;
  escalationLevel: string | null;
  resolutionsStatusOne: string | null;
  resolutionsStatusTwo: string | null;
  resolutionsStatusThree: string | null;
  securityStatusOne: string | null;
  securityStatusTwo: string | null;
  debtStatusOne: string | null;
  debtStatusTwo: string | null;
  repaymentProgressStatus: string | null;
  lenderTelephone: string | null;
  borrowerTelephone: string | null;
  currency: string | null;
  crimeReferenceNumber: string | null;
  surveyGoodCommunication: string | null;
  surveyWillContinue: string | null;
  surveyWillRecommend: string | null;
  surveyQuick: string | null;
  surveyImprove: string | null;
  surveyRecommendRating: number | null;
  surveyCreatedAt: string | null;
  surveyFeedbackScore: string | null;
  surveyDateCalled: string | null;
  surveyOverallHappy: string | null;
  surveyCallNotes: string | null;
  rcaResolvedBetweenThemselves: string | null;
  rcaCausedCase: string | null;
  badDebtActualised: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  /* joins */
  order: OrderDataType;
};

export type ReferralHistory = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  giverUserId: number;
  giverEarning: number;
  giverCouponId: number | null;
  receiverUserId: number;
  receiverCouponId: number;
  /* joins */
  giverUser: User;
  receiverUser: User;
};

export type Document = {
  id: number;
  flUuid?: string;
  userId: number;
  status: DocumentStatus;
  suspectedType: DocumentType;
  confirmedType?: DocumentType;
  personaVerificationId?: string;
  typeConfirmedAt?: Date;
  typeConfirmedBy?: string;
  url?: string;
  originIso2?: string;
  pinCode?: string;
  expiresAt?: Date;
  validatedBy?: string;
  validatedAt?: Date;
  reviewedBy?: string;
  rejectedBy?: string;
  rejectedAt?: Date;
  reviewedAt?: Date;
  rejectedReason?: string; // TODO: Make into Enum
  documentSubtype?: DocumentSubtype;
  details?: any;
  createdAt: Date;
  updatedAt: Date;
};

export type Requirement = {
  id: number;
  orderId: number;
  status: string;
  type: string;
  addedBy: string;
  createdAt: string;
  updatedAt: string;
  documentId?: number;
};

export type VerificationItem = Requirement & {
  document?: Document; // Document is optional as it might not exist in all items
};

export type VerificationsPayload = {
  requirementsAndDocuments: VerificationItem[];
  allDocuments: Document[];
};

// export interface RequirementBlock {
//   type: ReqTypes;
//   title: string;
// }

// export type DocumentRequirementBlock = Document &
//   RequirementBlock & { pinCode?: string };

// export type OnlinePresenceLinkRequirementBlock = Document & RequirementBlock;
// export interface BorrowerVerification {
//   rejected: OnlinePresenceLinkRequirementBlock[] & DocumentRequirementBlock[];
//   unfulfilled: OnlinePresenceLinkRequirementBlock[] &
//     DocumentRequirementBlock[];
//   underReview: OnlinePresenceLinkRequirementBlock[] &
//     DocumentRequirementBlock[];
//   completed: OnlinePresenceLinkRequirementBlock[] & DocumentRequirementBlock[];
// }
export type OrderStatusType =
  | "CHATTING"
  | "REQUESTED"
  | "DENIED"
  | "ACCEPTED"
  | "ACTIVE"
  | "CANCELED"
  | "COMPLETED";

type OrderItemDataType = {
  itemId: number;
  itemName: string;
  estimatedValue: number;
  imgUrls: string[];
  slug: string;
  city: string;
  lat: number | null;
  lng: number | null;
};
export enum CURRENCY {
  SEK = "SEK",
  NOK = "NOK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
  CAD = "CAD",
}
export enum AdminEventType {
  REQUESTED_EXTRA_DOCS = "REQUESTED_EXTRA_DOCS",
  REJECTED_DOCUMENT = "REJECTED_DOCUMENT",
  VALIDATED_DOCUMENT = "VALIDATED_DOCUMENT",
  ORDER_REJECTED = "ORDER_REJECTED",

  PAUSED_USER = "PAUSED_USER",
  UNPAUSED_USER = "UNPAUSED_USER",

  // DELETED_USER = 'DELETED_USER',
  // UNDELETED_USER = 'UNDELETED_USER',

  SENT_EMAIL_USER = "SENT_EMAIL_USER",
  SENT_TEXT_USER = "SENT_TEXT_USER",

  // TRANSACTION_CANCELLED = 'TRANSACTION_CANCELLED',
  // TRANSACTION_DATE_CHANGE = 'TRANSACTION_DATE_CHANGE',

  // UPDATED_ITEM = 'UPDATED_ITEM',

  USER_UPDATED = "USER_UPDATED",
  USER_COMMENT = "USER_COMMENT",

  PRODUCT_UPDATED = "PRODUCT_UPDATED",

  ORDER_PERIOD_CHANGED = "ORDER_PERIOD_CHANGED",
  ORDER_STATUS_CHANGED = "ORDER_STATUS_CHANGED",
  ORDER_COMMENT = "ORDER_COMMENT",

  ORDER_FUNDS_ADDED = "ORDER_FUNDS_ADDED",
  ORDER_FUNDS_REMOVED = "ORDER_FUNDS_REMOVED",
  ORDER_REFUND = "ORDER_REFUND", // REFUNDED?
  ORDER_PAYOUT = "ORDER_PAYOUT", // PAID_OUT?
  ORDER_APPROVED = "ORDER_APPROVED",
}

export type AdminEvent = {
  id: number;
  adminName: string;
  type: AdminEventType;
  data: any;
  description: string | null;
  reason: string | null;
  orderId: number | null;
  productId: number | null;
  userId: number | null;
  createdAt: Date;
};
export type OrderDataType = {
  orderId: number;
  items: OrderItemDataType[];
  itemNames?: string[];
  highestFraudRisk?: number;
  orderItemIds: number[];
  createdAt: Date;
  fromDate: Date | null;
  toDate: Date | null;
  currency: CURRENCY;
  totalEstimatedValue: number;
  orderFullTotal: number;
  totalDiscount: number;
  customerFee: number;
  ownerFee: number;
  ourRevenue: number;
  ownerEarnings: number;
  customerEmail: string;
  customerName: string;
  customerFirstName: string;
  customerId: number;
  ownerFirstName: string;
  ownerEmail: string;
  ownerId: number;
  ownerName: string;
  orderStatus: OrderStatusType;
  securityStatus: SecurityStatusType;
  deliveryStatus: DeliveryStatusType;
  orderStatusUpdatedAt: Date;
  orderStatusBecauseOf: OrderStatusBecauseOfType;
  paymentStatus: PaymentStatusType;
  paymentStatusUpdatedAt: Date | null;
  paymentStatusBecauseOf: PaymentStatusBecauseOfType;
  autoApproveDisabled: AutoApproveDisabled | null;
  timeCustomerWaitingForReview?: Date;
  adminEvents: AdminEvent[];
  stats?: UserStats;
  orderVerification?: OrderVerification;
};
export type DeliveryStatusType =
  | "NOT_DELIVERED"
  | "DELIVERED"
  | "RETURNED"
  | "UNAVAILABLE";
export interface GetCMSRentalPageResponse {
  order: OrderDataType;
  // stripe: any
  // seon: any
  overlappingOrders: Record<number, OrderDataType>;
  upcomingOrders: Record<number, OrderDataType>;
  // seonData: any
  // duplicates: Duplicates
}

export type SecurityStatusType =
  | "NOMINAL"
  | "FAILED_CREDIT_SCORE"
  | "FAILED_VERIFICATION"
  | "UNFULFILLED_REQS"
  | "IN_MANUAL_REVIEW"
  | "REJECTED";
//  | 'PROHIBITED'  //mm Don't think this can happen
//  | 'BLOCKED';  //mm Don't think this can happen

export type OrderStatusBecauseOfType = "CUSTOMER" | "VENDOR" | "AUTO";

export type PaymentStatusType =
  | "UNPAID"
  | "FULLY_PAID"
  | "FULLY_REFUNDED"
  | "FUNDS_RESERVED"
  | "PARTIALLY_REFUNDED";

export type PaymentStatusBecauseOfType = "CUSTOMER" | "VENDOR" | null;

/**
 * A User in their natural habitat with all fields available
 */
export interface User {
  id: number;
  /** Technically this is nullable, but only 11 rows exist */
  profileUuid: string;
  firstName: string;
  lastName: string;
  addressZip: string;
  birthDate: string;

  /** DANGER ZONE */
  passwordHash: string | null;

  // TODO change nullable fields to 'Maybe<string>'
  city: string;
  nationality: string | null;
  preferredCountry: string | null;
  preferredLocationUuid: string | null;

  signedUpWith: SignupSource | null;
  signupCountry: string | null;
  signupLocationUuid: string | null;

  paused: boolean;
  userRole: string;

  referrer: string | null;
  referralCode: string;
  credit: number;

  profileImgUrl: string;
  bio: string;
  badges: string[];

  // TODO change to a TS enum and unify all locations to that enum
  verifiedStatus: string;
  email: string;
  emailVerified: boolean;
  telephone: string | null;
  telephoneVerified: boolean;

  isSuperlender: boolean;
  responseTime: Maybe<number>;
  responseRate: Maybe<number>;

  // Rarely used stripe fields
  stripeFirstName?: string;
  stripeLastName?: string;
  stripeAddressLine1?: string;
  stripeAddressLine2?: string;
  stripeAddressCity?: string;
  stripeAddressPostalCode?: string;
  stripeCustomerId?: string;
  testStripeCustomerId?: string;

  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;

  partnershipUserUuid: string | null;

  tags: UserTags;
  dateOfBirth?: Date;
  signupIp?: string;
}

interface UserTags {
  [key: string]: number | boolean | string;
}

/**
 * <number of time units, discount percent when renting for longer than this period>
 * e.g. { 6: 10 } // 10% discount when renting for >= 6 months
 */
type DurationDiscountMap = Record<number, number>;

export interface PricingDiscountBoundariesConfig {
  timeUnit: LeaseTimeUnit;
  priceBoundaries: DurationDiscountMap;
}

/**
 * <number of [time unit], pence price PER [time unit] when rented for that duration>
 * e.g. { 6: 2000 } // when rented for 6 months, price per month is £20
 */
type DurationPriceMap = Record<number, number>;

/**
 * Specifies what type of time unit is being used, with Duration Price Map of prices
 */
export interface PricingSchema {
  timeUnit: LeaseTimeUnit;
  durationPriceMap: DurationPriceMap;
}

interface FeeConfig {
  borrowerFeePercentage: number;
  lenderFeePercentage: number;
}

export interface StepLogs {
  name: string;
  success: boolean;
  results: any;
}

export enum CancellationReason {
  FAILED_TRANSACTION_VERIFICATION_REQUIREMENTS = "FAILED_TRANSACTION_VERIFICATION_REQUIREMENTS",
  SUSPECTED_THIEF = "SUSPECTED_THIEF",
  KNOWN_THIEF = "KNOWN_THIEF",
  FAILED_DOCUMENT_CHECK = "FAILED_DOCUMENT_CHECK",
  UNABLE_TO_VERIFY = "UNABLE_TO_VERIFY",
  ABOVE_RENTAL_LIMIT = "ABOVE_RENTAL_LIMIT",
  USER_DECIDED_TO_CANCEL = "USER_DECIDED_TO_CANCEL",
  OTHER = "OTHER",
}

export enum AddressType {
  BILLING = "BILLING",
  PICKUP = "PICKUP",
  RENTAL_DELIVERY = "RENTAL_DELIVERY",
  /**
   * DROPOFF was being used for both sales and rentals
   * renamed to SALES_DELIVERY for clarity
   */
  SALES_DELIVERY = "DROPOFF",
}

export interface PaymentPlan {
  uuid: string;
  stripeSubscriptionId?: string;
  subscriptionFirstInvoiceAt?: Date;
  subscriptionCancelledAt?: Date;
  subscriptionAmount?: number;
  upfrontPaymentUuid?: string;
  tags?: object;
  log?: object;
  createdAt?: Date;
  updatedAt?: Date;
  stripeCardId?: string;
}

export interface Address {
  uuid: string;
  userId: number;
  type: AddressType;
  line1: string;
  line2: string | null;
  city: string;
  postalCode: string;
  countryUuid: string;
  lat: number | null;
  lng: number | null;

  createdAt: Date;
  updatedAt: Date;
}

export interface UserWarnings {
  id: number;
  reason: string;
  userId: number;
  adminUserId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}
